import React from 'react';
import { colors, mq, fontSizes, fontWeights } from '../../theme';
import Container from '../Container';
import { Heading2, Paragraph } from '../Heading/Text';
import BackgroundImage from '../BackgroundImage';
import Image from '../Image';
import Link from '../Link';

export const getLocationTab = () => {
  if (
    typeof window !== 'undefined' &&
    ['#tab-partenaires', '#tab-metiers'].includes(window.location.hash)
  ) {
    return window.location.hash;
  }

  return '#tab-partenaires';
};

const NosPartenairesHeader = ({ tab }) => {
  return (
    <>
      <Container
        background={colors.neutralWhite}
        css={mq({
          zIndex: 1,
          paddingBottom: [0],
          paddingTop: 0,
          height: [550, 'auto', 500],
          position: 'relative',
          alignItems: 'center'
        })}
      >
        <Container.ContentLeft size={70}>
          <Heading2 primary light>
            Des partenaires qualifiés
            <br />
            <span>pour tous les métiers</span>
          </Heading2>
          <Paragraph css={{ marginTop: 30 }}>
            Une liste de marques partenaires présentes
            <br />
            dans notre bibliothèque d’ouvrages, pour avoir tous vos produits
            <br />
            habituels à portée de main dans vos devis.
          </Paragraph>
        </Container.ContentLeft>

        <div
          css={mq({
            display: ['block', 'none', 'none'],
            position: ['absolute', 'relative'],
            bottom: 0,
            right: [-120, 0],
            width: 800,
            height: [550, 0, 0]
          })}
        >
          <BackgroundImage
            src="vincent.png"
            css={mq({
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'bottom center'
            })}
          />
        </div>
      </Container>

      <Container
        padding={false}
        background={colors.neutralDarker}
        css={mq({
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: [80, 30]
        })}
      >
        <div
          css={mq({
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: ['row', 'column'],
            width: ['calc(100% - 160px)', '100%', '100%'],
            position: 'relative',

            marginBottom: 60
          })}
        >
          {[
            {
              title: '33 marques',
              subtitle: 'référencées'
            },
            {
              title: '59 898',
              subtitle: 'matériaux ajoutés'
            },
            {
              title: '16 métiers',
              subtitle: 'représentés'
            }
          ].map((item, key) => (
            <div
              key={key}
              css={{ textAlign: 'center', textTransform: 'uppercase' }}
            >
              <Image
                alt="tick"
                css={mq({ width: 52, height: 44, marginBottom: [43, 35] })}
                src="tick.svg"
              />
              <h3
                css={mq({
                  letterSpacing: 1,
                  color: colors.brandColor,
                  lineHeight: ['45px', '32px'],
                  marginBottom: [59, 91]
                })}
              >
                {item.title}
                <div
                  css={mq({
                    fontSize: fontSizes.large,
                    color: colors.neutralWhite,
                    lineHeight: '20px'
                  })}
                >
                  {item.subtitle}
                </div>
              </h3>
            </div>
          ))}
        </div>
        <div
          css={{
            display: 'flex',
            border: `1px solid ${colors.neutralLight30}`,
            borderBottom: 0,
            width: '100%'
          }}
        >
          {[
            {
              name: 'Partenaires',
              href: '#tab-partenaires'
            },
            {
              name: 'Métiers',
              href: '#tab-metiers'
            }
          ].map(({ name, href }) => (
            <div
              key={name}
              id={href}
              css={{
                flex: 1,
                textAlign: 'center',
                cursor: 'pointer',
                fontWeight: fontWeights.semiBold,
                color: tab !== href ? colors.neutralWhite : 'inherit',

                backgroundColor: tab === href ? colors.brandColor : 'inherit'
              }}
            >
              <Link
                href={href}
                css={mq({
                  boxSizing: 'border-box',
                  display: 'block',
                  height: '100%',
                  width: '100%',
                  fontSize: [fontSizes.larger, fontSizes.medium],
                  letterSpacing: 1,
                  lineHeight: ['40px', '22px'],
                  padding: 20
                })}
              >
                {name}
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default NosPartenairesHeader;
